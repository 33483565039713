import './Signin.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import validator from 'validator';
import { v4 as uuid } from 'uuid'
import { URL } from '../socket'
import * as jose from 'jose'
import { useNavigate } from 'react-router-dom'
import CenteredLogo from '../components/CenteredLogo';
// import ReCAPTCHA from "react-google-recaptcha";

const Signin = ({ setIsLoggedIn, token, login, setCompanyID }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConf, setPasswordConf] = useState('')
    const [registerPasswordsOK, setRegisterPasswordsOK] = useState(false)

    const [emailLogin, setEmailLogin] = useState('')
    const [passwordLogin, setPasswordLogin] = useState('')
    const [loginDetailsOk, setLoginDetailsOk] = useState(false)

    const [error, setError] = useState(null)

    const navigate = useNavigate()



    useEffect(() => {
        if (password === passwordConf && password.length > 7 && password.match(/[0-9]/) && password.match(/[!@#$%^&*]/) && validator.isEmail(email)) {
            setRegisterPasswordsOK(true)
        } else {
            setRegisterPasswordsOK(false)
        }
    }, [password, passwordConf, email])

    useEffect(() => {
        if (validator.isEmail(emailLogin) && passwordLogin.length > 7) {
            setLoginDetailsOk(true)
        } else {
            setLoginDetailsOk(false)
        }
    }
        , [emailLogin, passwordLogin])

    const loginfAuth = async (email, password) => {
        try {
            const response = await axios.post(`${URL}/users/login`, { emailLogin: email, passwordLogin: password })
            if (response.data.ok) {
                let decodedToken = jose.decodeJwt(response.data.token);
                setCompanyID(response.data.companyID)
                login(response.data.token);
                // setTimeout(() => {
                //     navigate("/");
                // }, 2000);
            } else {
                alert('Invalid login details')
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        loginfAuth(emailLogin, passwordLogin)
    }

    const handleRegister = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${URL}/users/register`, {
                userEmail: email, userPassword: password, companyID: Date.now() + uuid() + 'u',
                userID: Date.now() + uuid(), userSignupDate: new Date()
            });
            if (response.data.ok) {
                loginfAuth(email, password)
            } else if (response.data.message === 'User exists!') {
                alert('This user already exists, please try to login instead.')
                setPassword('')
                setPasswordConf('')
            }
        } catch (error) {
            console.log(error);
        }
    }

    function handleCaptcha(value) {
        if (value) {
            setPassword('')
            setPasswordConf('')
            setEmail('')
        }
    }

        return (
            <section className="signin">
                <CenteredLogo />
                <article>

                    <div className='bubbleContainer'>
                        <form onSubmit={handleRegister}>
                            <h1>Sign up</h1>
                            <p>30-day free trial, no card requied</p>
                            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <input type="password" placeholder="Confirm password" value={passwordConf} onChange={(e) => setPasswordConf(e.target.value)} />
                            {/* add invisible input for a username hidden from peopel but filled out by bots */}
                            <input type="text" name="username" style={{ display: 'none' }} onChange={(e) => handleCaptcha(e.target.value)} />
                            <p className='tinyText max90width'>please use a strong password, at least 8 characters long, should contain at least 1 number and 1 special character</p>
                            {/* <ReCAPTCHA
                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            onChange={handleCaptcha}
                        /> */}
                            <button disabled={!registerPasswordsOK} type="submit">Sign up</button>
                        </form>
                    </div>

                    <p className='textCentered'>already registered? login below</p>

                    <div className='bubbleContainer'>
                        <form onSubmit={handleLogin}>
                            <h1>Sign in</h1>
                            <input type="email" placeholder="Email" value={emailLogin} onChange={(e) => setEmailLogin(e.target.value.toLowerCase())} />
                            <input type="password" placeholder="Password" value={passwordLogin} onChange={(e) => setPasswordLogin(e.target.value)} />
                            <button disabled={!loginDetailsOk} className='saveButton' >Sign in</button>
                            <p className='tinyText' onClick={() => { navigate('reset-password') }}>restore password</p>
                        </form>
                    </div>

                </article>
            </section>
        )
    }

    export default Signin;